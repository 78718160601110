/** profile/admin_list.html */

.users-table-wrapper {
  background-color: $white;
  padding: 24px;
}

.edit-user {

  margin-left: 12px;
  font-size: 1rem;

  @extend %fa-icon;
  @extend .fal;

  &:before {
    content: fa-content($fa-var-pencil);

  }
}

.edit-user-form {
  li {
    list-style: none;
  }
}

.recharts-brush-slide {
  fill: $theme-color-primary;
}

.recharts-brush-traveller {
  fill: darken($theme-color-primary, 20%);
}
;@import "sass-embedded-legacy-load-done:559";