/** projects/project.html */


.project-description, .project-lead, .project-creation, .project-expiration, .project-s3bucketname {
  display: flex;
  margin-bottom: 8px;

  .name {
    flex: 0 0 200px;
    display: inline-block;
    text-align: right;

    color: $dark-gray;
    margin-right: 4px;
  }

  .value {
    flex: 1 1 auto;
    max-width: 680px;
  }
}

.edit-project {

  margin-left: 12px;
  font-size: 1rem;

  @extend %fa-icon;
  @extend .fal;

  &:before {
    content: fa-content($fa-var-pencil);

  }
}

.project-members-card {


  .project-members-statistics-wrapper {
    padding: 24px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 24px;
  }

  .project-members-list-wrapper {

    .email-icon {
      @extend %fa-icon;
      @extend .fal;

      padding-left: 8px;

      &:before {
        content: fa-content($fa-var-envelope);
      }
    }

    .actions-cell form {
      display: inline;
    }

    .actions-cell {
      .no-actions {
        font-size: 0.8em;
        font-style: italic;
        color: $dark-gray;
      }

    }

    .approve-member:before {
      @extend %fa-icon;
      @extend .fal;
      margin-right: 10px;
      content: fa-content($fa-var-user-check);

    }

    .deny-member:before {
      @extend %fa-icon;
      @extend .fal;
      margin-right: 10px;
      content: fa-content($fa-var-user-times);
    }


    .edit-member:before {
      @extend %fa-icon;
      @extend .fal;
      margin-right: 10px;
      content: fa-content($fa-var-user-edit);
    }


    .remove-member:before {
      @extend %fa-icon;
      @extend .fal;
      margin-right: 10px;
      content: fa-content($fa-var-user-slash);
    }

  }
}

.project-experiments-card {


  .project-experiments-statistics-wrapper {
    padding: 24px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 24px;
  }

}


.project-settings-card {
  .project-invite-url {
    p {
      margin-bottom: 4px;
    }

    .invite-url {
      font-family: $font-family-monospace;
    }

    form {
      display: block;
      margin-top: 12px;
    }
  }

}
;@import "sass-embedded-legacy-load-done:560";