/** projects/pending_projects.html */

.header-checkboxes {
  display: flex;
  justify-content: flex-end;

  label {
    margin-left: 16px;
  }
}

.pending-projects-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
  }

  .pending-project {
    .card-header {
      h3 {
        display: inline-block;
      }

      span.pending-project-badge {
        font-size: 0.8rem;
        font-weight: bold;
        margin-left: 2rem;
        padding: 0.2rem 0.8rem;

        &.pending {
          background-color: get-color(secondary);
          color: $white;
        }

        &.approved {
          background-color: get-color(success);
          color: $white;
        }

        &.denied {
          background-color: get-color(alert);
          color: $white;
        }

        &.ignored {
          background-color: get-color(alert);
          color: $white;
        }
      }
    }

    .pending-project-details {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;

      & > div {
        padding-bottom: 0.5em;
        display: flex;
        align-items: start;

        select,
        input {
          max-width: 300px;
          margin: 0;
        }
      }

      .name {
        color: $dark-gray;
        display: inline-block;
        text-align: right;
        min-width: 140px;
        padding-right: 1rem;
      }

      .email-icon {
        @extend %fa-icon;
        @extend .fal;

        padding-left: 8px;

        &:before {
          content: fa-content($fa-var-envelope);
        }
      }

      .empty {
        font-style: italic;
        color: $dark-gray;
      }

      span.user-unapproved {
        background-color: get-color(warning);
        color: color-pick-contrast(get-color(warning));
        font-size: 0.875rem;
        padding: 4px;
        margin: 0 8px;
      }
    }

    .pending-project-actions {
      margin-left: 140px;

      .no-actions {
        font-size: 0.8em;
        font-style: italic;
        color: $white;
      }

      .approve-project:before,
      .approve-project-and-lead:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-user-check);
      }

      .deny-project:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-user-times);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:561";